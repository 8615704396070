// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
    // require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

// import stylesheets
import '../stylesheets/material-dashboard.scss'
import '../stylesheets/application.scss'

// import all image files in a folder:
require.context('../img', true);


$(function(){
	$('.destroy').remove();
	$('.lr-selected').not(':has(.stay)').remove();
});

$(window).scroll(function() {
	var btn = $("#back_to_top")
	if ($(window).scrollTop() > 300) {
		btn.addClass('show');
	} else {
		btn.removeClass('show');
	}
});

$(document).on("click","#back_to_top",function(e){
	e.preventDefault();
	$('html, body').animate({scrollTop:0}, '300');
});